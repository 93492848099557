import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Suspense, lazy, useState, useEffect } from 'react';

import Context from './Context';

import axios from "axios";

import { Helmet, HelmetProvider } from 'react-helmet-async';

const Header = lazy(() => import('./Components/Header'));
const Footer = lazy(() => import('./Components/Footer'));
const Cookies = lazy(() => import('./Components/Cookies'));
const ScrollToTop = lazy(() => import('./Components/ScrollToTop'));
const Home = lazy(() => import('./Pages/Home'));
const Empresa = lazy(() => import('./Pages/Empresa'));
const Produtos = lazy(() => import('./Pages/Produtos'));
const Produto = lazy(() => import('./Pages/Produto'));
const Contato = lazy(() => import('./Pages/Contato'));

function App() {
  const [categorias, setCategorias] = useState([]);
  const [configuracoes, setConfiguracoes] = useState({});
  const [isCartOpen, setCartOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [cart, setCart] = useState([]);
  const value = { cart, setCart, categorias, setCategorias, configuracoes, setConfiguracoes, isCartOpen, setCartOpen, modal, setModal };

  useEffect(() => {
    localStorage.setItem("apiUrl", "https://sistema.ardecoracao.com.br/");
    // localStorage.setItem("apiUrl", "http://localhost/");

    axios.get(localStorage.apiUrl + "/api/header").then((response) => {
      setCategorias(response.data.categorias)
      setConfiguracoes(response.data.configuracoes)
    });

    let carrinho = JSON.parse(localStorage.getItem("carrinho"));
    if (!carrinho) {
      carrinho = []
    }
    setCart(carrinho);
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <meta property="og:site_name" content="AR Artigos para Decoração" />
        <meta property="og:type" content="business.business" />
        <meta property="og:image" content="https://sistema.ardecoracao.com.br/public_images/original/banner.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="" />
        <meta name="twitter:image" content="https://sistema.ardecoracao.com.br/public_images/original/banner.jpg" />
      </Helmet>
      <Context.Provider value={value}>
        <div id="modal" className={modal ? "active" : ""}>
          {modal && <div className="content">
            <h3>{modal.titulo}</h3>
            <p>{modal.texto}</p>
            <button type="button" onClick={() => setModal(false)}>{modal.botao}</button>
          </div>}
        </div>
        <Router>
          <div className="App">
            <Suspense fallback={<div></div>}>
              <ScrollToTop />
              <Cookies />
              <Header />
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/empresa" exact>
                  <Empresa />
                </Route>
                <Route path="/contato" exact>
                  <Contato />
                </Route>
                <Route path="/produtos" exact>
                  <Produtos />
                </Route>
                <Route path="/produtos/:id/:slug" exact>
                  <Produtos />
                </Route>
                <Route path="/pesquisa/:pesquisa" exact>
                  <Produtos />
                </Route>
                <Route path="/produto/:id/:slug" exact>
                  <Produto />
                </Route>
              </Switch>
              <Footer />
              <p className="creditos">Desenvolvido por <a href="https://fw2propaganda.com.br/" target="_blank" rel="noopener noreferrer" title="FW2 Propaganda">FW2 Propaganda</a></p>
            </Suspense>
          </div>
        </Router>
      </Context.Provider>
    </HelmetProvider>
  );
}

export default App;
