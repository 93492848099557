import React from 'react';

const Context = React.createContext({
    activePage: false,
    setActivePage: () => { },
    isCartOpen: false,
    setCartOpen: () => { },
    cart: [],
    setCart: () => { },
    configuracoes: { },
    setConfiguracoes: () => { },
    categorias: [],
    setCategorias: () => { },
    modal: false,
    setModal: () => { }
});

export default Context;